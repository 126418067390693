import AWC, { Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div data-testid="text-input" x-modelable="value">
    <div :class="classes" class="text-input-container">
      <label
        :class="labelClasses"
        :for="id"
        class="text-input-label"
        x-text="label"
      ></label>
      <template x-if="multiline">
        <textarea
          :class="inputClasses"
          :disabled="disabled"
          :id="id"
          :name="name"
          :placeholder="label"
          :readonly="readonly"
          class="text-input-multi"
          x-model="value"
        ></textarea>
      </template>
      <template x-if="!multiline">
        <input
          :autocomplete="autocomplete"
          :class="inputClasses"
          :disabled="disabled"
          :id="id"
          :name="name"
          :placeholder="label"
          :readonly="readonly"
          :type="type"
          :pattern="pattern"
          :title="title"
          class="text-input"
          x-model="value"
        />
      </template>
    </div>
    <div
      :class="messageClasses"
      class="text-input-message"
      data-testid="text-input-message"
      x-text="message"
    ></div>
  </div>
`;

const attrs = {
  "container-class": Attribute.String(),
  "input-class": Attribute.String(),
  "input-id": Attribute.String(),
  "label-class": Attribute.String(),
  autocomplete: Attribute.String(),
  errors: Attribute.JSON<string[]>(),
  disabled: Attribute.Boolean(),
  help: Attribute.String(),
  label: Attribute.String(),
  multiline: Attribute.Boolean(),
  name: Attribute.String(),
  pattern: Attribute.String(),
  readonly: Attribute.Boolean(),
  title: Attribute.String(),
  type: Attribute.String(),
  value: Attribute.String(""),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  classes: () => string;
  id: () => string | undefined;
  inputClasses: () => string;
  labelClasses: () => string;
  message: () => string | undefined;
  messageClasses: () => string;
}

export class TextInput extends AWC<State, Attrs>("x-text-input", attrs, template) {
  data() {
    return {
      classes: () => {
        const { errors, readonly, "container-class": containerClass } = this.state;
        let cls = containerClass || "";

        if (errors && errors.length > 0) {
          cls += " border-sys-error";
        } else if (readonly) {
          cls += " border-ext-slate";
        }

        if (readonly) cls += " bg-ext-light-gray";

        return cls;
      },

      id: () => this.state["input-id"] || this.state.name,

      inputClasses: () => {
        const { errors, readonly, "input-class": inputClass } = this.state;
        let cls = inputClass || "";
        if (errors && errors.length > 0) cls += " placeholder-shown:text-sys-error";
        if (readonly) cls += " bg-ext-light-gray";
        return cls;
      },

      labelClasses: () => {
        const { messageClasses, value, "label-class": labelClass } = this.state;
        let cls = `${labelClass || ""} ${messageClasses()}`;
        if (value === "" && this.state.type !== "date") cls += " opacity-0";
        return cls;
      },

      message: () => {
        if (this.state.errors && this.state.errors.length) {
          return this.state.errors.join("\n");
        }
        return this.state.help;
      },

      messageClasses: () => {
        if (this.state.errors && this.state.errors.length) {
          return "text-sys-error";
        }
        return "";
      },
    };
  }
}

TextInput.define();

export default TextInput;
